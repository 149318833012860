
	import VuePictureCropper, {
		cropper
	} from 'vue-picture-cropper'
	import WangEditor from "../components/WangEditor"
	import {
		readFileContent
	} from "../util/index.js"
	import {
		ElMessage
	} from 'element-plus'
	//import iconfont from '../components/iconfont.vue';
	export default {
		data() {
			return {
				isloading: false,
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
				categories: [],
				artid: 0,
				isClear: false,
				lbpos: "right",
				artdetail: {
					Title: '',
					Brief: '',
					ImageUrl: '',
					SelCates: [],
					Tags: [],
					Author: '',
					Source: '',
					FromUrl: '',
					Status: 1,
					ArtDetail: '',
					CreatorOPID: 0,
					ModifyOPID: 0,
				},
				ImageUrl: '',
				SelCateTxt: [],
				showtaginput: false,
				TagInputVal: '',
				props: {
					label: 'Name',
					children: 'LHSTChildren',
				},
				count: 1,
				checkeddata: [],
				croperimg: '',
				showcroperdlg: false,
				aspectRatio: 0,
				cpage: 1,
			}
		},
		watch: {
			'artdetail.SelCates': {
				handler() {
					this.UpdateSelCateTxt();
				},
				deep: true
			},
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						// 打印clientWidth变化的值
						//console.log(that.clientWidth)
						if (that.clientWidth <= 640)
							that.lbpos = 'top';
						else
							that.lbpos = "right";
						that.timerw = false
					}, 400)
				}
			},
			clientHeight(val) {
				if (!this.timerh) {
					// 一旦监听到的clientHeight值改变，就将其重新赋给data里的clientHeight
					this.clientHeight = val
					this.timerh = true
					let that = this
					setTimeout(function() {
						// 打印clientHeight变化的值
						//console.log(that.clientHeight)
						that.timerh = false
					}, 400);
				}
			},
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.ArtDetail;
		},
		methods: {
			CropImage() {
				this.artdetail.ImageUrl = cropper.getDataURL();
				this.ImageUrl = this.artdetail.ImageUrl;
				this.showcroperdlg = false;
			},
			onFileSel(filelist) {
				if(filelist.length <= 0)
					return;
					
				let file = filelist[0];
				
				if(!file.name)
					return;
				
				var index= file.name.lastIndexOf(".");//获取最后一个.的位置
				var ext = file.name.substr(index+1).toLowerCase();//获取后缀
				var pattern = /^(jpg|jpeg|png|gif)$/;
				if(!pattern.test(ext))
				{
					ElMessage({
						message: this.PAGELANG.wjgsbzc,
						type: 'error',
					});
					return false;
				}

				readFileContent(file, 'dataUrl').then((contents) => {
					this.croperimg = contents;
					this.showcroperdlg = true;
					this.$refs.uploader.value = '';
				});
				
			},
			onExecCrop() {
				this.imageurl = cropper.getDataURL();
				this.showimgeditor = false;
				this.himgupdated = true;
			},
			UpdateSelCateTxt() {
				let nodes = this.$refs.catetree.getCheckedNodes();
				this.SelCateTxt = [];

				for (var key in nodes) {
					this.SelCateTxt.push(nodes[key].Name);
				}
				//console.log(this.SelCateTxt);
			},
			handleCheckChange(data, checked, indeterminate) {
				console.log(data, checked, indeterminate);
				if (checked)
					this.artdetail.SelCates.push(data.ID);
				else if (this.artdetail.SelCates.indexOf(data.ID) >= 0)
					this.artdetail.SelCates.splice(this.artdetail.SelCates.indexOf(data.ID), 1);

				//console.log(this.artdetail.SelCates);
			},
			handleNodeClick(data) {
				console.log(data)
			},
			onSubmit() {
				console.log(this.artdetail);
				this.axios.post(this.actions.saveart, this.artdetail).then((response) => {
					//console.log(response.data);
					if (response.data.retinfo == "ok") {
						this.$message(this.LANG.system.caozuochenggong);
						this.$router.go(-1);
					} else {
						this.$message(this.PAGELANG[response.data.retinfo]);
					}
				})

			},
			onNewTag() {
				this.showtaginput = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus()
				})
			},
			handleInputConfirm() {
				const TagInputVal = this.TagInputVal
				if (TagInputVal) {
					this.artdetail.Tags.push(TagInputVal)
				}
				this.showtaginput = false
				this.TagInputVal = ''
			},
			onCloseTag(t) {
				this.artdetail.Tags.splice(this.artdetail.Tags.indexOf(t), 1)
			},
			GoBack() {
				this.$router.go(-1);
			},
			formatDate(date) {
				var datetime = new Date(date * 1000);
				var year = datetime.getFullYear();
				var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
				var day = ("0" + datetime.getDate()).slice(-2);
				return year + "-" + month + "-" + day;
			},
			RefreshCates() {
				this.axios.get(this.actions.catetree, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: '0',
					}
				}).then((response) => {
					this.categories = response.data;
					//console.log(response.data);
					//this.artdetail.SelCates = ['1','2','3'];
					let that = this;
					setTimeout(function() {
						that.UpdateSelCateTxt();
					}, 300);
				});
			},
			GetArtInfo() {
				if (!this.artid)
					return false;

				this.axios.get(this.actions.artdetail + '&stamp=' + (new Date()).getTime(), {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						id: this.artid,
					}
				}).then((response) => {
					this.artdetail = response.data;
					if(this.artdetail.ImageUrl)
						this.ImageUrl = this.apibase + this.artdetail.ImageUrl;
						//console.log(this.ImageUrl);
					console.log(this.artdetail);
				});
			},
			DescChange(val) {
				//console.log(val)
				this.artdetail.ArtDetail = val;
			},
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
				this.clientHeight = document.documentElement.clientHeight;
			},
		},
		created() {
			window.addEventListener('resize', this.handleResize);
		},
		mounted() {
			if (this.$route.query.artid)
				this.artid = this.$route.query.artid;
			if (this.$route.query.cpage)
				this.cpage = this.$route.query.cpage;
			//console.log(this.artid);
			if (this.clientWidth <= 640)
				this.lbpos = 'top';
			else
				this.lbpos = "right";
			this.GetArtInfo();
			this.RefreshCates();
		},
		components: {
			//iconfont,
			WangEditor,
			VuePictureCropper
		}
	}
